
/*----------------------------------------------*/
/* Welcome Mat */
/*----------------------------------------------*/
.welcome-mat{
  background: lighten(grey, 25%);
  height: 340px;
  width: 100%;
  position: relative;
  text-align: center;
}

.welcome-mat-logo{
  padding-top:60px;
  img{
    max-width: 80%;
  }
}

.welcome-mat-subtext{
  color:white;
  padding-top: 10px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.welcome-mat-button-box{
  padding-top: 40px;
  radius{
    padding-left: 4px;
    padding-right: 4px;
  }
}

.welcome-svg{
  position: absolute;
  bottom: -1px;
  right: 0px;
  pointer-events: none;
}

.welcome-nav-input{
  display: inline-block;
    position: relative;
    bottom:3px;
  padding-left: 4px;
}

.welcome-text{
  position: absolute;
  top:0px;
  width:100%;
  max-width: 1200px;
}

.welcome-centerer{
  position: relative;
  max-width: 800px;
  text-align:center;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
}

.image.top{
  width: 100%;
}
.welcome-container{
  width: 100%;
  background-color: $darkestgrey;
  padding-bottom: 40px;
  padding-top: 1em;
  .title, .sub-head, .section-header, .textbox{
    color: $lightgrey;
  }
  img{
    pointer-events: none;
    padding-top: 100px;
  }
  @media screen and (max-width: 710px) {
    padding-bottom: 40px;
  }
}

.welcome-containerprocess{
  width: 100%;
  background-color: $darkestgrey;
  padding-top: 1em;
  margin-bottom: 40px;
  .title, .sub-head, .section-header, .textbox{
    color: $darkestgrey;
  }
  img{
    vertical-align:bottom;
    width: 100%;
  }
  .process-header {
    padding-bottom: 20px;
  }
  #process-container {
    @media screen and (max-width: 500px){
      display: none;
    }
  }
}

.mobile-remove{
  display: block;
  @media screen and (max-width: 650px){
    display: none;
  }
}

#process-container,#process-container-mobile {
  background-color: #F2F2F2;
  svg {
    width: 100%;
    height: auto;
  }
}

.mobile-welcome{
  display: none;
  @media screen and (max-width: 500px){
    display: block;
  }
}

.satellite-image{
  height:225px;
  width:225px;
  position:absolute;
  top: 450px;
  left: 0px;
    @media screen and (max-width: 800px) {
      height:180px;
      width:180px;
      top:470px;
    }
    @media screen and (max-width: 712px) {
      height:90px;
      width:90px;
      top:400px;
    }
    @media screen and (max-width: 712px) {
      top:340px;
    }
  }

/*----------------------------------------------*/
/* Explanatory Bits */
/*----------------------------------------------*/
.explanatory{
  width: 800px;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  font-size: 1.4em;
  text-align: center;
}

.explanatory-bg{
  z-index: -1;
  width:100%;
  height: 240px;
}
/*----------------------------------------------*/
/* Actionable Bits */
/*----------------------------------------------*/
.action-container{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  padding: 10px 0px 10px 15px;
  max-width: 1000px;
  border-radius: 20px;
  .textbox{
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .columns {
    padding-left: 0;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 80px;
  }
}

.contribute-base {
  @media screen and (max-width: 1024px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }
}

.image-action {
  @media screen and (max-width: 340px) {
    width: 200px;
  }
}

.contribute-container {
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  padding: 30px 0px 20px 0px;
}

.validate-container {
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 30px 0px 20px 0px;
}

.no-deco-list li {
  list-style: none;
}

.actions-img{
  text-align: center;
}

.contribute-image{
  p {
    margin-top:10px;
  }
@media screen and (max-width: 1024px) {
    width: 100%;
    text-align: center;
}
}

.right-box{
  text-align: right;
}

@media screen and (min-width: 64em){
  .large-push-4 {
    left: -33.333%;
  }
}
/*----------------------------------------------*/
/* OSM Analytics */
/*----------------------------------------------*/
.osmanalytic-container{
  width: 100%;
  background-color: $darkestgrey;
  padding-bottom: 3em;
  margin-top: 2em;
  padding-top: 1em;
  margin: auto;
  text-align: center;
  .title, .section-header, .textbox{
    color: $lightgrey;
  }
  .textbox{
    p{
    color: #797b7b;
    }
  }
  ::selection{
    background: rgba(247, 224, 127, 0.10);
  }
}

.osmanalytic-item-container{
  padding: 30px 15px 20px;
  text-align: center;
  border-radius: 20px;
  .title {
    display: block;
    margin-top: .6em;
  }
}

.userprof-nav-input{
    position: relative;
    padding-top: 20px;
  input{
  margin-left: auto;
  margin-right: auto;
  margin-bottom:60px;
  }
}

.alt-svg{
  bottom: 2px;
  position: absolute;
  right: 0px;
  pointer-events: none;
}

.Discover-Stats{
  width: 100%;
}

.Discover-Split{
  display: inline-block;
}

.Stat-Item{
  width: 260px;
  display: inline-block;
  margin: 2em 0;
  .title{
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: 600;
    color: #797b7b;
  }
  .emphasizedNumber{
    color: $mmTeal;
    font-size: 2em;
    font-weight: 800;
    line-height: 20px;
    padding-top:10px;
    padding-bottom: 2px;
  }
  img{
    margin: 0 auto 10px auto;
    display: block;
  }
}

.Discover-Title{
  font-size: 1.3em;
  color: #797b7b;
  font-family: "Hind";
  padding-bottom: 1em;
}

.Discover-Sub{
  font-size: 1.3em;
  color: #797b7b;
  font-family: "Hind";
  font-weight: 400;
  text-align: center;
  padding-bottom: 1em;
}

.Discover-Tools{
  padding-bottom: 4em;
  .title{
    width:100%;
    padding-top:0.5em;
  }
}

/*----------------------------------------------*/
/* Events Analytics */
/*----------------------------------------------*/
.events-container{
  padding: 30px 10px;
  background-color: $mmTeal;
  text-align: center;
  width:100%;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

.clear {
  clear: both;
}

.events-panel {
  background-color: $mmTeal;
  text-align: center;
  width: 100%;
  padding-bottom:30px;
}

.event-top-section{
  background-color: lighten($darkgrey, 48);;
  padding: 5px 10px;
  border-radius: 2px 2px 0 0;

  @media screen and (max-width: 450px) {
      padding: 0px 6px 15px 10px;
    }

  .sub-head{
    float: left;
    padding-bottom: 0;
    width: 100%;
    text-align: left;
    @media screen and (max-width: 580px) {
      font-size: 1.2em;
    }
  }
  .btn-grn{
    float: right;
    line-height: 12px;
    font-size: .60em;
    margin: 5px 0 5px 0;
    @media screen and (max-width: 580px) {
      font-size: 0.7em;
      margin-top: 7px;
    }
  }
}

.event-sub-container{
  max-width: 600px;
  background-color: white;
  border-radius: 2px;
  margin-bottom: 1.5em;
  box-shadow: 0px 1px $lightgrey;
  border: 1px solid $border-grey;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 470px) {
    margin: 0 10px 20px;
  }
}

.event-images {
  float: left;
  display: block;
  margin-right: 10px;
  padding-top: 9px;
}

.event-header {
  float: left;
  width: 70%;
  padding-top: 9px;
  line-height: 35px;
  margin-bottom: 4px;
}

.event-page{
  width: auto;
  background-color: lighten($lightgrey,10%);
  padding-bottom: 60px;
}

.event-details-left{
  padding: 10px 20px;
  float: left;
  margin-top: 15px;
  border-right: solid 1px $lightgrey;
  .emphasizedNumber{
    color: $darkgrey;
    font-size: 2em;
    font-weight: 800;
    line-height: 20px;
    margin-top:2px;
    padding-top:15px;
  }
}

.event-details-right{
  float: left;
  padding: 8px 0 14px 20px;
  width: 75%;
  text-align: left;

  @media screen and (max-width: 470px) {
    width: 60%;
  }
  p{
    padding: 0;
    line-height: 1.3em;
  }
  .event-details {
    font-size: .8em;
    margin-top: 5px;
  }
  button{
    position:absolute;
    top:0;
    left:0;
  }
}

.event-maindetails {
  padding: 10px;
}

.event-block{
  height:50px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.header-image{
  .welcome-svg{
    position: absolute;
    bottom: 0px;
  }
}

/*----------------------------------------------*/
/* About Analytics */
/*----------------------------------------------*/
.about-container{
  width: 70%;
  text-align: center;
  margin: auto;
  padding: 0px 0px 2em 0px;
  @media screen and (max-width: 710px) {
    width: 100%;
  }
  p{
    margin-bottom: 8px;
  };
  img{
    margin: 0px 16px 0px 16px;
    margin-bottom: 10px;
  };
  &.top{
    margin-bottom:0px;
  }
  .sub-head{
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
}


.about-textbox{
  padding-left: 14px;
  text-align: left;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.4em;
  span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
  h1{
    display: inline;
  }
  li{
    padding-bottom:10px;
    list-style-position: inside;
  }
}

.about-textboxfirst{
  width:70%;
  padding-left: 14px;
  padding-bottom: 20px;
  text-align: left;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.4em;
  span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
  h1{
    display: inline;
  }
}

/*----------------------------------------------*/
/* Footer Analytics */
/*----------------------------------------------*/
.footer-container{
	padding: 30px 0px 0px 0px;
	background-color: $lightgrey;
	width: 100%;
	.sectionheader{
		padding-bottom: 36px;
    padding-top: 20px;
	}
}

.footer-mm{
	margin-top:50px;
	height: 50px;
	width: 100%;
	line-height: 50px;
	color: $lightgrey;
	background-color: $darkestgrey;
}

/*----------------------------------------------*/
/* Map Guides */
/*----------------------------------------------*/
.pageheader{
  margin-top: 50px;
  padding-top: 10px;
  margin-bottom: 28px;
  font-size: 35px;
}

.linebreak{
  max-width: 500px;
  margin: 2px auto 0px auto;
  padding-bottom: 20px;
}

.contribute-options{
  max-width: 700px;
  margin-top: 40px;
  margin-bottom: 60px;
  a{
    margin: 0;
  }
  img{
    width: 150px;
  }
  .contribute-button{
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contribute-caps{
  text-transform: uppercase;
}

.videobox-light{
  padding: 10px 30px 60px 30px;
  .sub-head{
    padding-top: 10px;
    font-size: 1.2em;
  }
  hr{
    margin: 2px 0px 10px 0px;
  }
}

.videobox{
  padding: 10px 30px 60px 30px;
  .sub-head{
    padding-top: 10px;
    font-size: 1.2em;
    color: #b0b0b0;
  }
  .textbox{
    p{
      color: #b0b0b0;
    }
    li {
      color: #b0b0b0;
    }
  }
  hr{
    margin: 2px 0px 10px 0px;
  }
}

ol{
  padding-left: 20px;
}

hr{
  margin: 40px 0px 0px 0px;
}

.mmvideo{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;

}

.host-button-box{
  text-align: center;
  margin-top: 20px;
}

.host-container{
  background-color: lighten($lightgrey, 10%);
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.6em;
}

.host-textbox{
  text-align:left;
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.6em;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  input{
      width: 13px;
      height: 13px;
      padding:0;
      margin: 0;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      overflow: hidden;
      background-color: lighten($lightgrey, 10%);
  }
}

.host-image{
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.host-materials{
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.6em;
  padding-top: 10px;
  padding-bottom: 40px;
  text-align: center;
  li {
  background-color: lighten($lightgrey, 10%);
  }
}

.about-button{
  margin-top:40px;
}

.padding-extra{
  margin-top: 40px;
}

.arw{
  position: relative;
  padding-right: 30px;
  img{
  width: 15px;
  position: absolute;
  right: 8px;
  top: 10px;
  }
}

.OSM-logo{
  img{
  width: 100px;
  height: 100px;
  }
}
