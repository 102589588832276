/*----------------------------------------------*/
/*About Page*/
/*----------------------------------------------*/

.about-contact {
	margin: 4px 0px 60px 0px;
}

.orderedlist-styled li {
	list-style-type: none;
}
