/**
 * Reset ==============================================================
 * Based on http://meyerweb.com/eric/tools/css/reset
 */

html,
body,

/* Structures */
div,
span,
applet,
object,
iframe,

/* Text */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,

/* Lists */
dl,
dt,
dd,
ol,
ul,
li,

/* Forms */
fieldset,
form,
input,
select,
textarea,
label,
legend,

/* Tables */
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin:0px;
  padding:0px;
  border:0px;
  outline:0px;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  line-height:inherit;
  }

ol,
ul,
.item-list ul,
.item-list ul li {
  list-style:none;
  }

blockquote,
q { quotes:none; }

blockquote:before,
blockquote:after,
q:before, q:after {
  content:'';
  content:none;
  }

/* remember to define focus styles! */
:focus { outline:0px; }

/* remember to highlight inserts somehow! */
ins { text-decoration:none; }
del { text-decoration:line-through; }
