/*----------------------------------------------*/
/* Blog Page */
/*----------------------------------------------*/

.blog-preview{
  margin-bottom: 30px;
  overflow: auto;
  .blog-date{
    font-style: italic;
    font-weight: 300;
  }
}

.blog-container{
  margin-bottom: 30px;
  .blog-date{
    font-style: italic;
    font-weight: 300;
  }
  h1 {
    font-size: 1.4em;
    font-weight: 600;
  }
  h2 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 6px 0;
  }
  figure{
    text-align: center;
  }
  img{
    margin: auto;
    margin-bottom: 2px;
    clear: both;
    border: 1px solid $lightgrey;
  };
  .caption{
    text-align: center;
    font-size: 0.8em;
    font-style: italic;
  }
  .blog-end{
    text-align: center;
    max-width: 150px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .author{
    text-align: center;
  }
  .blog-image {
    max-height: 150px;
  }
}

/*Tags*/
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  h2{
    padding: 5px 0;
    font: 20px/2em 'Hind';
  }
}

.tags li {
  float: left;
}

.tag {
  font: 15px/1.5 'Lato', serif;
  background: $mmGreen;
  border-radius: 3px 0 0 3px;
  color: #FFFFFF;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag-archive-font {
  font-size: 13px;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #F2F2F2;
  // border-bottom: 13px solid transparent;
  border-left: 10px solid $mmGreen;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: darken($mmGreen, 12%);
  color: white;
  text-decoration: none;
}

.tag:hover::after {
  border-left-color: darken($mmGreen, 12%);
}
